import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Row, Col, OverlayTrigger, Popover } from "react-bootstrap";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { faUserCog as faUserCogReg } from "@fortawesome/pro-regular-svg-icons";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";
import decode from "jwt-claims";
import moment from "moment";
import "moment/locale/fr";
import "moment/locale/ar";
import { getCurrentUserRequest, regionRequest } from "../actions";
import { getAccountFromToken } from "../lib/util";
import { Trans, useTranslation, useI18next } from "gatsby-plugin-react-i18next";

/**
 * displays account information for the authenticated user
 * @param {any} dispatch - dispatches actions to trigger state changes
 * @param {any} account_jwt - bearer token
 * @param {any} account_jwt_refresh - refresh token
 * @param {any} [account_email=""] - current user email address
 * @param {any} [account_first_name=""] - current user first name
 * @param {any} [account_last_name=""] - current user last name
 */
const AccountProfile = ({
  dispatch,
  account_jwt,
  account_jwt_refresh,
  account_email = "",
  account_first_name = "",
  account_last_name = "",
}) => {
  const { language } = useI18next();
  const { t, i18n } = useTranslation();
  const { user_id } = getAccountFromToken(account_jwt);
  const { unique_region_text } = useSelector(
    (state) => !state.region_loading && state.region_data
  );

  moment.locale(language);

  const decoded_jwt_refresh = account_jwt_refresh
    ? decode(account_jwt_refresh)
    : null;
  const refresh = decoded_jwt_refresh
    ? moment.unix(decoded_jwt_refresh.exp)
    : "N/A";

  // retrieves the current user's name and stores in state
  useEffect(() => {
    dispatch(getCurrentUserRequest(user_id));
  }, [dispatch, user_id]);

  useEffect(() => {
    document.dir = i18n.dir();
    dispatch(regionRequest(language));
  }, [dispatch, language, i18n]);

  return (
    <Layout
      pageName="help"
      siteTitle={unique_region_text?.siteTitle ?? ""}
      siteDescription={unique_region_text?.siteDescription ?? ""}
    >
      <Helmet>
        <title>{unique_region_text?.siteTitle ?? ""}</title>
      </Helmet>
      <div
        className={`px-md-4 account-container ${
          language === "ar" ? "custom-rtl-dir" : ""
        }`}
      >
        <Row>
          <Col>
            <h2 className="py-4">
              <FontAwesomeIcon
                icon={faUserCogReg}
                className="mr-3"
                fixedWidth
              />
              <Trans>Account profile</Trans>
            </h2>
          </Col>
        </Row>
        <dl className="account-dl">
          <dt>
            <Trans>Name</Trans>
          </dt>
          <dd>
            {`${account_first_name} ${account_last_name}`}

            {!!(account_first_name || account_last_name) && (
              <Link to="/account/change-name/" className="ml-3">
                <small>{t("(change)")}</small>
              </Link>
            )}
          </dd>
          <dt>
            <Trans>Email Address</Trans>
          </dt>
          <dd>
            {account_email}{" "}
            <Link to="/account/change-email/" className="ml-3">
              <small>{t("(change)")}</small>
            </Link>
          </dd>
          <dt>{t("Password")}</dt>
          <dd>
            ****************
            <Link to="/account/reset-password/" className="ml-3">
              <small>{t("(change)")}</small>
            </Link>
          </dd>
          <dt>
            <Trans>Session Expires</Trans>
          </dt>
          <OverlayTrigger
            placement="right"
            overlay={
              <Popover id="popover-basic">
                <Popover.Content>
                  {`
                      ${t("Your session expires on")} 
                      ${
                        moment.isMoment(refresh)
                          ? refresh.format("dddd, MMMM Do YYYY, h:mm a")
                          : refresh
                      }
                    `}
                </Popover.Content>
              </Popover>
            }
          >
            {({ ref, ...triggerHandler }) => (
              <dd {...triggerHandler}>
                {moment.isMoment(refresh) ? moment().to(refresh) : refresh}
                <sup ref={ref}>
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    className="ml-2 text-primary"
                  />
                </sup>
              </dd>
            )}
          </OverlayTrigger>
        </dl>
      </div>
    </Layout>
  );
};

export default AccountProfile;
