import { graphql } from "gatsby";
import React from "react";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMap } from "@fortawesome/pro-regular-svg-icons";
import Layout from "../../components/Layout";
import AccountProfile from "../../components/AccountProfile";
import SidebarAccount from "../../components/SidebarAccount";
import NavLink from "../../components/NavLink";

const Account = (props) => {
  return (
    <Layout
      pageName="Account"
      secondaryNav={
        <NavLink
          linkPath="/"
          linkText="Map"
          linkIcon={<FontAwesomeIcon icon={faMap} size="lg" />}
        />
      }
    >
      <Container fluid>
        <SidebarAccount {...props} />
        <AccountProfile {...props} />
      </Container>
    </Layout>
  );
};

export default connect((state) => state)(Account);

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
